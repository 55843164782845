import api from './api'
export default {
    getAffiliate(){
        return api.execute(`get`,`/moderator/aff`)
    },
    getAffiliateLogs(data){
        return api.execute(`post`,`/moderator/aff/logs`,data)
    },
    getSourceList(){
        return api.execute(`get`,`/moderator/aff/source`)
    },
    getUserInteractEvents(){
        return api.execute(`get`,`/moderator/user/events`)
    },
    updateUserInteractEvent(data){
        return api.execute(`put`,`/moderator/user/events`,data)
    },
}